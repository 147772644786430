.ContactWrapper {
    text-align: center;
    margin-top:20px;
    font-family: LatoLight;
    font-size: 20px;
    color:#1D7373;
}

.contactCard {
    color: #1D7373;
    background-color: white;
    width:30vw;
    min-width:660px;
    height: 100px;
    font-size: 18px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 10px;
    margin-top: 20px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: row;
}

.authorName {
    text-align: center;
    color: white;
    width: 200px;
    font-family: LatoBold;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 100px;
}

.authorName.HCI {
    background-color: #1d7373;
}

.authorName.robotics {
    background-color: #A64B00;
}

.contactDetail {
    text-align: left;
    line-height: 50px;
    text-align: left;
    color:black;
}

.contactDetails {
    text-align: left;
    display: flex;
    flex-direction: column;
}

.contactIcon {
    width: 50px;
}

.contactIcon.robotics {
    color:#A64B00;
}