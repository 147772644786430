.modaleDescriptionHeader {
    height: 50px !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.SectionTitle {
    text-align: left;
    font-size: 15px;
    margin: 10px;
    font-family: LatoBold;
    color: #1D7373;
}
.FormDescribeCompareFilter {
    overflow-y: auto;
    margin-bottom: 10px;
}

.DescribeCompareFormElement {
    padding:5px;
    display: flex;
    background-color: #F5F5F5;
    flex-direction: row;
    margin: 5px;
    height:20px;
    text-align: left;
    line-height: 20px;
}

.DescribeCompareFormElement.ShapeChange {
    height:280px;
}

.DescribeCompareFormElement.Safety {
    height:80px;
}

.DescribeCompareFormElement.Packing {
    height:60px;
}

.void20px {height: 20px;}

.ShapeChangeFeaturesWrapper {
    flex-direction: column;
}

.DescribeCompareObjectTitle.Feature {
    font-family: Lato-LightItalic;
    margin-left: 20px;
    margin-right: -10px;
}

.DescribeCompareObjectTitle {;
    margin-left: 10px;
    width: 190px;
    font-size: 12px;
    font-family: Lato;
}

.DescribeCompareObjectValue {
    margin-left: 20px;
    font-size: 11px;
    color:black;
    font-family: LatoLight;
    width: 270px;
}

.formTitleSeparator  {
    height:1225px;
    width:3px;
    margin-top: -5px;
    background-color: white;
}