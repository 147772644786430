
.CheckboxWrapper {
    margin:auto;
}

.FormFilter {
    overflow-y: auto;
    margin-bottom: 60px;
}

.FilterFormElement {
    padding:5px;
    display: flex;
    background-color: #F5F5F5;
    flex-direction: column;
    margin-bottom: 5px;
    margin-top:5px;
    height:50px;
}

.FilterFormElement.highlighted {
    background-color: white;
}

.formObjectTitle {
    text-align: left;
    font-family: Lato;
    color:#666666;
    font-size: 14px;
    left:10px;
    margin-bottom: 10px;
}

.FilterFormElement.highlighted .formObjectTitle {
    font-family: LatoBold;
    color:#1D7373;
}

.FormAnswersWrapper {
    display: flex;
}

.FilterFormElement {
    margin-left:10px;
}

.FormAnswersWrapper {
}

.FormAnswersWrapper > label {
    text-align: center;
}

.DimensionalityForm .FormAnswersWrapper > label {
    width: 30%;
}

.ActuationForm .FormAnswersWrapper > label {
    width: 25%;
}

.HierarchyForm .FormAnswersWrapper > label,
.SurfaceForm .FormAnswersWrapper > label {
    width: 50%;
}

.Centered > div:nth-child(2) {
    margin: auto;
}

.modaleSeparator {
    margin:auto;
    width:90%;
    height:1px;
    background-color: rgb(235, 235, 235);
}
