.btnPlus {
    width:30px;
    height:30px;
    background: url('./img/icones.svg') 0 0;
}

.btnPlusHover {
    width:30px;
    height:30px;
    background: url('./img/icones.svg') 0 -30px;
}

.btnEdit {
    width:30px;
    height:30px;
    background: url('./img/icones.svg') -30px 0;
}

.btnEditHover {
    width:30px;
    height:30px;
    background: url('./img/icones.svg') -30px -30px;
}

.btnDelete {
    width:30px;
    height:30px;
    background: url('./img/icones.svg') -60px 0;
}

.btnDeleteHover {
    width:30px;
    height:30px;
    background: url('./img/icones.svg') -60px -30px;
}

.btnCross {
    width:30px;
    height:30px;
    background: url('./img/icones.svg') -90px 0;
}

.btnCrossHover {
    width:30px;
    height:30px;
    background: url('./img/icones.svg') -90px -30px;
}