.ObjectRequirementWrapper {
    width:40vw;
    max-width:500px;
    min-width:350px;
    margin-bottom:10px;
    margin-right:auto;
    margin-left: auto;
    cursor: pointer;
    font-family: Lato;
}

.PropertiesListWrapper {
    display: flex;
    flex-direction: row;
    margin-right:auto;
    margin-left: auto;
    min-width: 800px;
    max-width: 1300px;
    width: 100vw;
}

.PropertiesList {
    width: 50%;
}

.PropertiesListTitle {
    text-align: center;
    background-color: #1D7373;
    color: white;
    width:40vw;
    max-width:500px;
    min-width:350px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    margin-right:auto;
    margin-left: auto;
    margin-bottom: 10px;
    margin-top: 20px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
}


.ObjectRequirementMin {
    position:relative;
    display:flex;
    flex-direction: row;
    height:40px;
    z-index:10;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    background-color:white;
}

.idRequirement {
    height:40px;
    width:50px;
    color: #009999;
    font-size:20px;
    font-family: LatoLight;
    line-height: 40px;
    text-align: center;
}

.digitalLevel {
    color: #009999;
}

.physicalLevel {

}

.interactionLevel {

}

.intraLevel {

}

.moduleLevel {

}

.intraLevel {

}

.levelRequirement {
    height:40px;
    width:140px;
    color: #009999;
    font-size:16px;
    font-family: LatoLight;
    line-height: 40px;
    text-align: center;
}

.idRequirementSeparator{
    height:30px;
    width:1px;
    background-color: #009999;
    margin-top: 5px;
}

.titleRequirement {
    line-height: 40px;
    margin-left: 20px;
}

.ObjectRequirementMin:hover {
    font-family: LatoMedium;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.dropdownDescription {
    text-align: justify;
    z-index:1;
    color: #2D2D2D;
    font-size:14px;
}

.dropdownDescription.visible {
    transition: 0.5s;
    position:relative;
    padding:10px;
    background-color:white;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
}

.dropdownDescription.hidden {
    transition: 0.5s;
}