.CheckboxWrapper {
    display: flex;
    flex-direction: row;
}

.CheckboxLabel {
    margin-left:20px;
    font-size: 12px;
}

.CheckboxWrapper input:checked ~ .CheckboxLabel {
    color: #1D7373;
    font-family: Lato;
}

.Checkbox {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.Checkmark {
    display: block;
    cursor: pointer;
    height: 15px;
    width: 15px;
    background-color: #eee;
}

.CheckboxWrapper:hover input ~ .Checkmark {
    background-color: #ccc;
}

.CheckboxWrapper input:checked ~ .Checkmark {
    background-color: #009999;
    transition: 0.5s;
}

.Checkmark:after {
    content: "";
    display: none;
}

.CheckboxWrapper input:checked ~ .Checkmark:after {
    display: block;
}

.CheckboxWrapper .Checkmark:after {
    position: relative;
    left: 5px;
    top: 1px;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}