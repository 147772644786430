.PageTitle {
    color:black;
    font-size:18px;
    font-family: LatoBold;
    height:20px;
    width:100vw;
    min-width: 1000px;
    text-align: center;
}

.TitleSeparator {
    width:400px;
    height:1px;
    background-color: #1D7373;
    margin-top:20px;
    margin-right:auto;
    margin-left: auto;
    margin-bottom: 20px;
}

.intro {
    width: 100%;
    font-family: Lato;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 30px;
}