.WrapperSystem {
    margin:10px;
    position:relative;
    display:flex;
    flex-direction: column;
    height:350px;
    width:300px;
    background-color:white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.10);
    cursor: pointer;
}

.systemText{
    width:100px;
}

.paperDomain {
    width:100%;
    height:20px;
    font-size: 14px;
    line-height: 20px;
    color:white;
    text-decoration: none;
    text-align: center;
    z-index:3;
}

.a {
    text-decoration: none;
}

.paperDomain.HCI {
    background-color: #1D7373;
}

.paperDomain.Robotics {
    background-color: #A64B00;
}

.imgSystem {
    width:100%;
    height:200px;
    object-fit: cover;
}

/*.abstract {
    visibility: hidden;
    position: absolute;
    text-decoration: none;
    font-family: Lato;
    width:200px;
    height:150px;
    text-align: left;
    margin-left:45px;
    margin-top:35px;
    line-height: 17px;
    font-size: 10px;
    overflow: scroll;
    text-overflow: ellipsis;
    padding: 10px;
    color: rgba(0,0,0, 0);
    background-color: rgba(255,255,255, 0);
}*/

.chooseCompare.active {
    visibility: visible;
}

.detailsSystem, .chooseCompare {
    visibility: hidden;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.20);
    width:100%;
    height:200px;
    margin-top: 20px;
}

.btnCompare, .btnDescribe, .btnChooseCompare {
    font-family: Lato;
    width: 100px;
    height: 40px;
    line-height: 40px;
    border-radius: 2px;
    text-align: center;
    color:white;
    margin-left:100px;
}

.describeCenter {
    margin-top:25% !important;
}

.btnChooseCompare {
     background-color: #A64B00;
     margin-top:70px;
     width: 120px;
 }

.btnChooseCompare:hover {
    background-color: #FF9640;
}

.btnDescribe {
    background-color: #1D7373;
    margin-top:50px;
}

.btnDescribe:hover {
    background-color: #009999;
}

.btnCompare {
    margin-top:10px;
    background-color: #A64B00;
}

.btnCompare:hover {
    background-color: #FF9640;
}

.WrapperSystem:hover {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
}

.WrapperSystem:hover .paperTitle {
    text-decoration: underline;
    color: #1D7373;
}

.WrapperSystem:hover .detailsSystem {
    visibility: visible;
}

.WrapperDetails {
    text-align: left;
    display: flex;
    flex-direction: column;
    position: relative;
}

.WrapperDetails .paperTitle{
    font-family: LatoBlack;
    font-size: 14px;
    margin-left: 5px;
    margin-top:10px;
    margin-bottom:10px;
}

.WrapperDetails .paperAuthors{
    font-family: Lato;
    font-size: 12px;
    margin-left: 5px;
    margin-bottom:5px;
}

.WrapperDetails .paperProceedings{
    font-family: Lato-LightItalic;
    font-size: 12px;
    margin-left: 5px;
}