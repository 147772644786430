.FilterObjectWrapper {
    height:40px;
    display:flex;
    flex-direction: row;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.10);
    margin-right: 10px;
    margin-top: 10px;
}

.filterTitle {
    line-height: 40px;
    margin-left: 10px;
    margin-right:10px;
    color:#555555;
    font-family: Lato;
    font-size: 16px;
}

/*
    EDIT BUTTON
*/

.btnEditWrapper {
    right:0;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.8);
    width:30px;
    height: 30px;
    padding-top: 5px;
    padding-left: 5px;
}

.btnEditWrapper .btnEdit{
    display: flex;
}

.btnEditWrapper .btnEditHover{
    display: none;
}

.btnEditWrapper:hover .btnEdit{
    display: none;
}

.btnEditWrapper:hover .btnEditHover{
    display: flex;
    cursor: pointer;
}

.FilterObjectWrapper:hover .btnEditWrapper {
    display: flex;
    transition: 0.3s;
}

.FilterObjectWrapper .btnEditWrapper {
    display: none;
    transition: 0.3s;
}

/*
    DELETE BUTTON
*/

.btnDeleteWrapper {
    right:0;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.8);
    width:30px;
    height: 30px;
    padding-top: 5px;
    padding-left: 5px;
}

.btnDeleteWrapper .btnDelete{
    display: flex;
}

.btnDeleteWrapper .btnDeleteHover{
    display: none;
}

.btnDeleteWrapper:hover .btnDelete{
    display: none;
}

.btnDeleteWrapper:hover .btnDeleteHover{
    display: flex;
    cursor: pointer;
}

.FilterObjectWrapper:hover .btnDeleteWrapper {
    display: flex;
    transition: 0.3s;
}

.FilterObjectWrapper .btnDeleteWrapper {
    display: none;
    transition: 0.3s;
}
