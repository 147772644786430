.ContactWrapper {
    text-align: center;
    margin-top:20px;
    font-family: LatoLight;
    font-size: 20px;
    color:#1D7373;
}

.contactList {
    margin-top:50px;
}