@font-face {
    font-family: Lato;
    src: url('./font/Lato/Lato-Regular.ttf');
}

@font-face {
    font-family: LatoLight;
    src: url('./font/Lato/Lato-Light.ttf');
}

@font-face {
    font-family: LatoMedium;
    src: url('./font/Lato/Lato-Medium.ttf');
}

@font-face {
    font-family: LatoBold;
    src: url('./font/Lato/Lato-Bold.ttf');
}

@font-face {
    font-family: LatoBlack;
    src: url('./font/Lato/Lato-Black.ttf');
}

@font-face {
    font-family: Lato-LightItalic;
    src: url('./font/Lato/Lato-LightItalic.ttf');
}