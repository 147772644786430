.FilterWrapper {
    width:90vw;
    margin-left:5vw;
}

.FilterSeparator {
    background-color: #666666;
    height:1px;
    width:90vw;
    margin-top:20px;
}

.FiltersList {
    display: flex;
    flex-flow:row wrap;
}

.AddFilterBtn {
    margin-top:5px;
    display: flex;
    width:130px;
    margin-left:20px;
    margin-top: 15px;
}

.AddFilterBtn .btnPlusHover {
    display: none;
}

.AddFilterBtn .btnPlus {
    display: flex;
}

.AddFilterBtn:hover {
    cursor:pointer;
}

.AddFilterBtn:hover .textAddFilter {
    color:#00CC00;
}

.AddFilterBtn:hover .btnPlusHover {
    display: flex;
}

.AddFilterBtn:hover .btnPlus {
    display: none;
}

.textAddFilter {
    line-height: 30px;
    margin-left: 5px;
    color: #008500;
    font-family: LatoBlack;
}