.SystemListWrapper {
    margin-right:10vw;
    margin-left: 10vw;
}

.SystemList {
    display: flex;
    flex-flow:row wrap;
    width: fit-content;
    justify-content: center;
}

