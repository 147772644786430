.SwitchWrapper {
    width:40px;
    height:15px;
    background-color:#E3E3E3;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.5s;
}

.SwitchWrapper:hover .SwitchHandle{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}

.SwitchHandle {
    margin-top: -2.5px;
    width:20px;
    height:20px;
    background-color:white;
    border-radius: 30px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
    transition: 0.5s;
 }

.SwitchWrapper.checked {
    background-color: #5CCCCC;
    transition: 0.5s;
}

.SwitchWrapper.checked .SwitchHandle {
    background-color: #009999;
    margin-left:20px;
    transition: 0.5s;
}
