.SystemComparedNames {
    margin-top: 10px;
    margin-left: 208px;
    display: flex;
    flex-direction: row;
}

.SystemCompareName {
    text-align: center;
    width: 288px;
    font-family: Lato;
    height: 30px;
    line-height: 30px;
    margin-left: 3px;
    background-color: #1D7373;
    color: white;
    /*background-color: #F5F5F5;*/
}

.different {
    font-family: LatoBold;
    color: #BF7130;
}

.formTitleSeparatorComparison {
    height:1200px;
    width:3px;
    margin-top: -5px;
    background-color: white;
}