.FilterModaleWrapper {
    position:absolute;
    top:0;
    left:0;
    z-index: 10;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
}

.FiltersListWrapper {
    overflow-y: auto;
    margin-top: 10px;
}

.backgroundModale {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: absolute;
    background-color: rgba(0,0,0, 0.8);
}

.ContentModaleWrapper {
    position: fixed;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width:800px;
    min-height: 400px;
    max-height: 80vh;
    top: 70px;
    left: 50%;
    transform: translate(-50%, 0%);
    margin-top:30px;
    background-color: white;
    border-radius: 10px;
}

.CheckboxWrapper {
    margin:auto;
}

.modaleHeader {
    height:100px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.choiceMacroMicro {
    width: 100%;
    display: flex;
    margin-top: 10px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
}

.choiceMacro, .choiceMicro {
    width: 50%;
    background-color: #F5F5F5;
    border: 2px solid white;
    color:#666666;
    border-radius: 2px;
    font-family: LatoLight;
    cursor: pointer;
}

.choiceMacro.active, .choiceMicro.active {
    font-size: 15px;
    background-color: white;
    color: #1D7373;
    border-left: 1px solid #1D7373;
    border-right: 1px solid #1D7373;
    border-top: 1px solid #1D7373;
    font-family: Lato;
}

.modaleTitle {
    text-align: center;
    font-family: LatoBold;
    color:#1D7373;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    margin-top: 5px;
}

.modaleDescription {
    text-align: center;
    font-size: 14px;
    height: 20px;
    font-family: LatoLight;
    margin-bottom: 5px;
}

.FormFilter {
    overflow-y: auto;
    margin-bottom: 60px;
}

.FilterFormElement {
    padding:5px;
    display: flex;
    background-color: #F5F5F5;
    flex-direction: column;
    margin-bottom: 5px;
    margin-top:5px;
    height:50px;
}

.FilterFormElement.highlighted {
    background-color: white;
}

.formObjectTitle {
    /*width:210px;*/
    text-align: left;
    font-family: LatoBold;
    color:#666666;
    font-size: 16px;
    left:10px;
    margin-bottom: 10px;
}

.FilterFormElement.highlighted .formObjectTitle {
    font-family: LatoBold;
    color:#1D7373;
}

.FormAnswersWrapper {
    display: flex;
}

.FilterFormElement {
    margin-left:10px;
}

.FormAnswersWrapper {
}

.FormAnswersWrapper > label {
    text-align: center;
}

.DimensionalityForm .FormAnswersWrapper > label {
    width: 30%;
}

.ActuationForm .FormAnswersWrapper > label {
    width: 25%;
}

.HierarchyForm .FormAnswersWrapper > label,
.SurfaceForm .FormAnswersWrapper > label {
    width: 50%;
}

.Centered > div:nth-child(2) {
    margin: auto;
}


/*
    FOOTER
 */

.modaleFooter {
    z-index: 2;
    background-color: white;
    position:absolute;
    bottom:0;
    width:100%;
    height:60px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.optionBtn {
    margin:auto;
    width:250px;
    margin-top: 10px;
}

.saveBtn {
    border-radius: 3px;
    background-color: #008500;
    border: none;
    color:white;
    font-family: Lato;
    font-size: 20px;
    width:120px;
    height:40px;
    line-height: 40px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.saveBtn:hover {
    background-color: #00CC00;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
}

.cancelBtn {
    border-radius: 3px;
    background-color: #A60000;
    border: none;
    color:white;
    font-family: Lato;
    font-size: 20px;
    width:120px;
    height:40px;
    line-height: 40px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    margin-right:10px;
}

.cancelBtn:hover {
    background-color: #FF0000;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
}

.modaleSeparator {
    margin:auto;
    width:90%;
    height:1px;
    background-color: rgb(235, 235, 235);
}


.closeBtnModaleWrapper {
    float: right;
    width:30px;
    height: 30px;
    margin-right: 5px;
    margin-top: 5px;
}

.closeBtnModaleWrapper .btnCrossHover {
    display: none;
}

.closeBtnModaleWrapper .btnCross {
    display: flex;
}

.closeBtnModaleWrapper:hover {
    cursor:pointer;
}

.closeBtnModaleWrapper:hover .btnCrossHover {
    display: flex;
}

.closeBtnModaleWrapper:hover .btnCross {
    display: none;
}
