.App {
  min-width: 1000px;
  font-family: Lato;
}

.html {
  height: 100%;
}


.AppContent {
  position:relative;
  z-index:1;
  padding-top:120px;
}

.link {
  color:#1D7373;
  cursor: pointer;
  text-decoration: underline;
}

.link:hover {
  color:#009999;
}