.NavbarWrapper {
    top:0;
    width: 100%;
    position: fixed;
    min-width: 1000px;
    z-index: 3;
}

.UpperNavbar {
    display: flex;
    justify-content: center;
    width:100%;
    height:60px;
    background-color:#1D7373;
    box-shadow: 0 0 10px black;
    transition: 0.3s;
    /*z-index: 3;*/
    position: relative;
}

.UpperNavbar.hidden {
    transition: 0.3s;
    height:0px;
    background-color: rgba(29, 115, 115, 1);
}

.UpperNavbarTitle {
    color:white;
    font-family: LatoMedium;
    font-size: 20px;
    line-height: 20px;
}

.LowerNavbar {
    box-shadow: 0 0 10px black;
    background-color: white;
}

.NavbarSeparator {
    background-color: #FFB273;
    display:flex;
    width:100%;
    height:5px;
    z-index: 2;
    position: relative;
}

.ThumbnailWrapper {
    height:30px;
    display:flex;
    flex-direction: row;
    z-index: 1;
    position: relative;
    margin:auto;
}

.Thumbnail {
    width: 33vw;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: white;
    color: #2D2D2D;
    font-family: Lato;
    font-size:14px;
    cursor: pointer;
    min-width: 250px;
}

.Thumbnail:hover {
    color: #009999;
    box-shadow: 0 0 15px #888888;
}

.Thumbnail.active {
    background-color: #009999;
    color:white;
    font-family: LatoBold;
    box-shadow: 0 0 12px #888888;
}

.Thumbnail.active:after,
.Thumbnail.active:after {
    z-index: -1;
    display:flex;
    margin-left: auto;
    margin-right: auto;
    content: '';
    width:0;
    height: 0;
    border-top: solid 5px #009999;
    border-left: solid 20px transparent;
    border-right: solid 20px transparent;
}

.ThumbnailSeparator {
    height:30px;
    width:1px;
    background-color: #1D7373;
}